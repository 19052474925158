<template>
  <section id="sectionVideos">
    <h2>{{ $t('headers.videos') }}</h2>
    <div id="videos" class="videos">
      <iframe
        width="560"
        height="400"
        src="https://www.youtube.com/embed/videoseries?list=PLGHP4pKg0HD3GR5RB_qUaVJnSOY0BAYxf"
        title="YouTube video player"
        frameborder="0"
        allow="
          accelerometer;
          autoplay;
          clipboard-write;
          encrypted-media;
          gyroscope;
          picture-in-picture"
        allowfullscreen>
      </iframe>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Videos',
};
</script>

<style lang="scss" scoped>
.videos {
  max-width: 1200px;
  margin: auto;

  iframe {
    width: 90%;
    border: 1px solid var(--color-shades-dark);
    border-radius: 10px;
  }
}
</style>
