<template>
  <section id="sectionMusic">
    <videos />
    <lyrics />
  </section>
</template>

<script>
import Videos from '@/components/music/Videos.vue';
import Lyrics from '@/components/music/Lyrics.vue';

export default {
  name: 'Music',
  components: {
    Videos,
    Lyrics,
  },
};
</script>

<style>

</style>
