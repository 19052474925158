<template>
  <section id="sectionLyrics" class="sectionLyrics">
    <h2>{{ $t('headers.lyrics') }}</h2>
    <div class="songs">
      <ul class="songList">
        <li
          v-for="song in songList"
          :key="song"
          class="songTitle"
          @click="setActiveLyrics(song.title, song.lyrics)"
        >
          {{ song.title }}
        </li>
      </ul>
      <div class="lyricContainer">
        <h3>{{ activeLyricsTitle }}</h3>
        <p>{{ activeLyrics }}</p>
      </div>
    </div>
  </section>
</template>

<script>
import { songs } from '@/assets/songs.json';

export default {
  name: 'Lyrics',
  data() {
    return {
      songList: songs,
      activeLyricsTitle: '',
      activeLyrics: '',
    };
  },
  methods: {
    setActiveLyrics(lyricsTitle, lyrics) {
      this.activeLyricsTitle = lyricsTitle;
      this.activeLyrics = lyrics;
    },
  },
};
</script>

<style lang="scss" scoped>
.songs {
  max-width: 800px;
  margin: auto;
  border: 1px solid var(--color-shades-dark);
  border-radius: 10px;
  display: flex;

}
.songList{
  text-align: left;
  flex-grow: 1;
}

.lyricContainer {
  border-left: 1px solid var(--color-shades-dark);
  flex-grow: 3;
  white-space: pre-line;
}

.songTitle {
  cursor: url('/cursor_hand.png'), auto;
}
</style>
